import React from "react";

import { useRouter } from "next/router";

import styles from "./GetInTouchComponent.module.css";

const GetInTouchComponent = (props) => {
  const router = useRouter();

  return (
    <section className={styles.getintouch_container}>
      <div className="container-fluid">
        <div
          className={`d-flex align-items-center justify-content-between flex-wrap flex-column flex-md-row ${styles.getintouch_section}`}
        >
          <div className={styles.getintouch_section_content}>
            <h3 className={styles.getintouch_section_content_heading}>
              Don&apos;t be Shy,<b> Say Hi.</b>
            </h3>
            <p className={styles.getintouch_section_content_para}>
              Ready for the Starlight experience?
            </p>
          </div>
          <button
            className={styles.getintouch_section_btn}
            onClick={() => router.push("/contact-us")}
          >
            Get In Touch <img src="/assets/images/right_arrow.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default GetInTouchComponent;
