import React, { useEffect, useState, useRef } from "react";

import Slider from "react-slick";

import styles from "./TestimonialComponent.module.css";
import { AxiosInstance } from "../../../utilities";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        dots: true,
        arrows: false,
      },
    },
  ],
};

const TestimonialComponent = (props) => {
  const [testimonials, setTestimonials] = useState([]);
  const bgRef = useRef(null);

  // Lazy load the background image for the testimonial container
  useEffect(() => {
    if (bgRef.current) {
      const observer = new IntersectionObserver(
        (entries, observerInstance) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const el = entry.target;
              const bgUrl = el.getAttribute("data-bg");
              if (bgUrl) {
                el.style.backgroundImage = `url('${bgUrl}')`;
                observerInstance.unobserve(el);
              }
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(bgRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    fetch_homepage_cms_data();
  }, []);

  const fetch_homepage_cms_data = () => {
    AxiosInstance(4008, "application/json")
      .post("/pages", { slug: "home" })
      .then((resp) => {
        const response = resp && resp.data;
        if (response && (response.status === 200 || response.status === "200")) {
          const response_Data = response.data;
          response_Data &&
            response_Data.data.map((item) => {
              if (item.key === "testimonials") {
                setTestimonials(item.content);
              }
              return null;
            });
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };

  return (
    <section className={styles.testimonial_container}>
      {/* 
        Remove static background image from CSS.
        Instead, add a data-bg attribute and a ref so that the IntersectionObserver can set it when needed.
      */}
      <div
        ref={bgRef}
        className={styles.testimonial_container_bg}
        data-bg="/assets/images/testimonial_bg.svg"
      ></div>
      <div className={styles.conatiner_fluid}>
        <div className={styles.testimonial_heading_section}>
          <img
            loading="lazy"
            src="/assets/images/quotes.png"
            alt="quotes_image"
            width={50}
            height={40}
          />
          <h2 className={styles.testimonial_heading}>
            {props.testimonials_heading
              ? props.testimonials_heading
              : "Testimonials"}
          </h2>
        </div>
        <div className={styles.testimonial_slider}>
          <Slider {...settings}>
            {testimonials && testimonials.length > 0
              ? testimonials.map((item) => (
                  <div key={item._id}>
                    <div className={styles.testimonial_slide}>
                      <figure className={styles.testimonial_slide_figure}>
                        <img
                          loading="lazy"
                          src={
                            item.value !== ""
                              ? process.env.IMAGE_LINK + item.value
                              : "/assets/images/empty_image_placeholder.jpg"
                          }
                          alt=""
                          width={631}
                          height={501}
                        />
                      </figure>
                      <div className={styles.testimonial_slide_content}>
                        <div>
                          <h6
                            className={
                              styles.testimonial_slide_content_imageheading
                            }
                          >
                            {item.imageHeading}
                          </h6>
                          <p className={styles.testimonial_slide_content_para}>
                            {item.description}
                          </p>
                          <h6
                            className={styles.testimonial_slide_content_heading}
                          >
                            {item.title}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default TestimonialComponent;
