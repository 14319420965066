import React, { useState, useEffect } from "react";

import dynamic from "next/dynamic";
import Link from "next/link";

import styles from "./FooterNavComponent.module.css";
import { formatPhoneNumber } from "../../../helper/service";
import { AxiosInstance } from "../../../utilities";

// Lazy load the LiveChat component, disabling SSR and adding a loading fallback
const LazyLiveChat = dynamic(() => import("react-livechat"), {
  ssr: false,
  loading: () => <></>,
});

const FooterNavComponent = (props) => {
  const [footerData, setFooterData] = useState(props.footerData);
  const [initChat, setInitChat] = useState(false);
  const [contactNo, setContactNo] = useState("");

  useEffect(() => {
    let data = {
      showAll: false,
    };
    // Disable fetching if already done by server-side rendering
    if (!props.footerData) {
      fetch_footer_cms_data(data);
    }
  }, []);

  useEffect(() => {
    setInitChat(true);
  }, [props]);

  useEffect(() => {
    const num = formatPhoneNumber(footerData?.contact);
    setContactNo(num);
  }, [footerData]);

  const fetch_footer_cms_data = (data) => {
    AxiosInstance(4008, "application/json")
      .post("/footerLinks", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (response && (response.status === 200 || response.status === "200")) {
          const response_Data = response.data;
          const index = response_Data?.cms?.findIndex(
            (item) => item.slug === "about-us"
          );
          if (index !== -1) {
            response_Data?.cms?.splice(index, 1);
          }
          setFooterData(response_Data);
        } else if (response && (response.status === 400 || response.status === "400")) {
          setFooterData(null);
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };

  return (
    <section className={`text-center text-sm-start ${styles.footernav_container}`}>
      <div className="container-fluid">
        <div className={`row ${styles.footernav_section}`}>
          <div className="col-lg-3">
            <h2 className={styles.footernav_heading}>Starlight</h2>
            <ul className={`row ${styles.footernav_list}`}>
              {footerData && footerData.cms && footerData.cms.length > 0
                ? footerData.cms.map((item) => (
                    <li key={item?._id} className={`col-12 ${styles.footernav_list_item}`}>
                      <Link href={item?.slug === "home" ? "/" : "/" + item?.slug}>
                        <a className={styles.footernav_heading_text}>
                          {item?.slug === "our-talent" ? "About Us" : item?.title}
                        </a>
                      </Link>
                    </li>
                  ))
                : ""}
              <li key={"credits"} className={`col-12 ${styles.footernav_list_item}`}>
                <Link href={"/credits"}>
                  <a className={styles.footernav_heading_text}>Credits</a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <h2 className={styles.footernav_heading_browse}>Browse Bands</h2>
            <ul className={`row ${styles.footernav_list}`}>
              {footerData && footerData.bands && footerData.bands.length > 0
                ? footerData.bands.map((item) => (
                    <li key={item._id} className={`${styles.footernav_list_item}`}>
                      <Link href={`/band/${item.slug}`}>
                        <a className={styles.footernav_heading_text}>{item.name}</a>
                      </Link>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
          <div className="col-lg-3">
            <div className={styles.footernav_list_section}>
              <h2 className={styles.footernav_heading}>Contact Us</h2>
              <h6 className={styles.footernav_list_heading}>Start Conversation</h6>
              {footerData && footerData.contact && (
                <a href={`tel:${contactNo}`} className={styles.footernav_list_phone}>
                  {contactNo}
                </a>
              )}
            </div>
            <div className={styles.footernav_list_section}>
              <h6 className={styles.footernav_list_heading}>Social Media</h6>
              <ul className={`d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap ${styles.footernav_list_social}`}>
                {footerData &&
                footerData.socialMedia &&
                footerData.socialMedia.length > 0
                  ? footerData.socialMedia.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target="_blank"
                          className="img_btn"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              item.socialMedia === "instagram"
                                ? "/assets/images/insta_icon.svg"
                                : item.socialMedia === "linkedin"
                                ? "/assets/images/linkedin_icon.svg"
                                : item.socialMedia === "facebook"
                                ? "/assets/images/fb_icon.svg"
                                : ""
                            }
                            width={41}
                            height={41}
                            alt={item.socialMedia}
                          />
                        </a>
                      </li>
                    ))
                  : ""}
                <li key={"1footerDemoPhotos"} className={`col-12 ${styles.footernav_list_item_custom} mt-4`}>
                  Demo photos by
                  <a
                    className={styles.footernav_heading_text}
                    href={"https://www.melanilustphotography.com/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" Melani Lust "}
                  </a>
                  Photography
                </li>
                <li key={"2footerDemoVideos"} className={`col-12 ${styles.footernav_list_item_custom} mt-3`}>
                  Demo Videos recorded at
                  <a
                    className={styles.footernav_heading_text}
                    href={"https://guastavinos.com/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" Guastavino's "}
                  </a>
                  and
                  <a
                    className={styles.footernav_heading_text}
                    href={"http://quadnyc.com/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" Quad Studios "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap flex-column-reverse flex-md-row ${styles.copyright_section}`}>
          <p className={styles.copyright_section_text}>
            © {new Date().getFullYear()} Starlight Music. All rights reserved
          </p>
          <ul className={`d-flex align-items-center flex-column flex-md-row ${styles.terms_link}`}>
            <li className={`mb-3 mb-md-0 ${styles.terms_link_item}`}>
              <Link href={`/terms-and-conditions`}>
                <a>Terms & Conditions</a>
              </Link>
            </li>
            <li className={`mb-3 mb-md-0 ${styles.terms_link_item}`}>
              <Link href={`/privacy-polices`}>
                <a>Privacy Policy</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="LiveChat">
        {initChat && (
          <LazyLiveChat license={parseInt(process.env.LIVECHAT_LICENSE)} />
        )}
      </div>
    </section>
  );
};

export default FooterNavComponent;
