/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Image from "next/image";
import Slider from "react-slick";

import styles from "./TrustedByComponent.module.css";
import { AxiosInstance } from "../../../utilities";

const settings = {
  dots: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TrustedByComponent = (props) => {
  const [trustedBy, set_trusted_by] = useState(props.trustedByData || []);

  useEffect(() => {
    fetch_homepage_cms_data();
  }, []);

  const fetch_homepage_cms_data = () => {
    // var previewId = props.location && props.location.pathname;
    // previewId = previewId.replace("/preview/home/", "");
    let params = { slug: "home" };
    // if (previewId !== "" && previewId !== "/") {
    //   let paramsClone = params;
    //   paramsClone.previewKey = previewId;
    // }
    AxiosInstance(4008, "application/json")
      .post("/pages", params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const response_Data = response.data;
          response_Data &&
            response_Data.data.forEach((item) => {
              if (item.key === "trustedBy") {
                set_trusted_by(item.content);
              }
            });
        }
      })
      .catch((err) => {
        console.log("err---", err?.message);
      });
  };
  return (
    <section className={styles.trustedby_container}>
      <div className="container-fluid">
        <h4 className={styles.trustedby_heading}>TRUSTED BY</h4>
        <ul className={styles.trustedby_list}>
          <Slider {...settings}>
            {trustedBy.length > 0 &&
              trustedBy.map((item, index) => (
                <div key={index}>
                  <li className={styles.trustedby_list_item} key={item._id}>
                    <Image
                      loading="lazy"
                      src={
                        item.value !== ""
                          ? process.env.IMAGE_LINK + item.value
                          : "/assets/images/empty_image_placeholder.jpg"
                      }
                      alt={`trusted_by_${index}`}
                      width={140}
                      height={140}
                    />
                  </li>
                </div>
              ))}
          </Slider>
        </ul>
      </div>
    </section>
  );
};

export default TrustedByComponent;
